import * as THREE from 'three';
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader';
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry';

/* eslint-disable */
export const addDashedLine = (
  parentMesh: THREE.Mesh,
  handleLines: (lines: THREE.Line[]) => void,
  floorWidth: number,
  floorLength: number
) => {
  const dashedMaterial = new THREE.LineDashedMaterial({
      color: '#333',
      gapSize: 3,
  });

  // Geometry for the lines
  const leftLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(floorWidth / 2 - 50, 300, 0),
      new THREE.Vector3(-floorWidth / 2 - 50, 300, 0),
  ]);
  const rightLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(floorLength / 2 + 50, 300, 0),
      new THREE.Vector3(-floorLength / 2, 300, 0),
  ]);

  const leftLine = new THREE.Line(leftLineGeometry, dashedMaterial);
  const rightLine = new THREE.Line(rightLineGeometry, dashedMaterial);

  // Compute line distances for dashes
  leftLine.computeLineDistances();
  rightLine.computeLineDistances();

  leftLine.position.z = 27;
  rightLine.position.z = 27;
  rightLine.rotation.z = (90 * Math.PI) / 180;

  parentMesh.add(leftLine);
  parentMesh.add(rightLine);

  const leftLine2 = leftLine.clone();
  const rightLine2 = rightLine.clone();

  leftLine2.position.y = 200;
  rightLine2.position.x = 200;

  rightLine.position.y = floorLength / 2 - 25;
  rightLine2.position.y = floorLength / 2 - 25;
  leftLine.position.x = floorWidth / 2 + 25;
  leftLine2.position.x = floorWidth / 2 + 25;

  parentMesh.add(leftLine2);
  parentMesh.add(rightLine2);

  leftLine.userData.id = 'leftLine';
  leftLine2.userData.id = 'leftLine2';
  rightLine.userData.id = 'rightLine';
  rightLine2.userData.id = 'rightLine2';

  handleLines([leftLine, leftLine2, rightLine, rightLine2]);

  leftLine.visible = false;
  leftLine2.visible = false;
  rightLine.visible = false;
  rightLine2.visible = false;

  return {
      leftLine,
      leftLine2,
      rightLine,
      rightLine2,
  };
};

export const addArrowsAndText = (
  wall: THREE.Mesh,
  width: number,
  handleArrows: (
      arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[]
  ) => void,
  isSideWall: boolean = false,
  measurement = 0,
  wallHeight = 0,
  id = ''
) => {
  // Arrow Helper
  const arrowDir = new THREE.Vector3(1, 0, 0).normalize();
  const arrowLength = width - 50 - (isSideWall ? 25 : 0);
  const arrowColor = '#333';

  // Create left arrow
  const leftArrow = new THREE.ArrowHelper(
      arrowDir.clone().negate(), // Direction
      new THREE.Vector3(width - 75 + (isSideWall ? 25 : 0), wallHeight, 12.5), // Start point
      arrowLength,
      arrowColor,
      15,
      15
  );
  wall.add(leftArrow);

  // Create right arrow
  const rightArrow = new THREE.ArrowHelper(
      arrowDir,
      new THREE.Vector3(width + 75, wallHeight, 12.5),
      arrowLength,
      arrowColor,
      15,
      15
  );
  wall.add(rightArrow);

  // Text for width
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = 256;
  canvas.height = 128;

  context!.font = '50px Arial';
  context!.fillStyle = '#000';
  context!.textAlign = 'center';
  context!.fillText(`${measurement} mm`, canvas.width / 2, canvas.height / 2);

  const texture = new THREE.CanvasTexture(canvas);
  const textMaterial = new THREE.SpriteMaterial({map: texture});
  const textSprite = new THREE.Sprite(textMaterial);
  textSprite.position.set(width + (isSideWall ? 25 : 0), wallHeight, 20);
  textSprite.scale.set(100, 50, 1);

  wall.add(textSprite);

  rightArrow.userData.id = id;
  leftArrow.userData.id = id;
  textMaterial.userData.id = id;

  handleArrows([rightArrow, leftArrow, textMaterial]);

  leftArrow.visible = false;
  rightArrow.visible = false;
  textMaterial.visible = false;
};

export const addArrowsAndTextForCabinetDimension = (
  wall: THREE.Mesh,
  width: number,
  handleArrowsAndGroup: (
  arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[],
  arrowGroup?: THREE.Group<THREE.Object3DEventMap>,
  ) => void,
  isSideWall: boolean = false,
isLshapedRoom: boolean = false,
isTopWall : boolean = false,
id = ''
) => {
  const arrowDir = new THREE.Vector3(1, 0, 0).normalize();
const reverseArrowDir = arrowDir.clone().negate();
  let arrowLength = width - 10;
const arrowColor = 0x333333;
const arrowHeadLength = 10;
const arrowHeadWidth = 8;

const createArrow = (origin: THREE.Vector3, direction: THREE.Vector3) => {
  const arrow = new THREE.ArrowHelper(
    direction,
    origin,
    arrowLength,
    arrowColor,
    arrowHeadLength,
    arrowHeadWidth
  );
  
  return arrow;
};

// Create left and right arrows
const leftArrowOrigin = new THREE.Vector3(width - 15, 25, 40);
const rightArrowOrigin = new THREE.Vector3(15, 25, 40);

const leftArrow = createArrow(leftArrowOrigin, reverseArrowDir);
const rightArrow = createArrow(rightArrowOrigin, arrowDir);

const arrowGroup = new THREE.Group();
arrowGroup.userData.id = id || (
  isSideWall && isTopWall ? 'arrowGroupLeftTopWall' :
  isSideWall ? 'arrowGroupLeft' :
  isTopWall ? 'arrowGroupTopTopWall' : 'arrowGroupTop'
);

  arrowGroup.add(rightArrow);
  arrowGroup.add(leftArrow);

  wall.add(arrowGroup);

  // Load font and create text
  const loader = new FontLoader();
  loader.load('/templates/3D/fonts/Poppins SemiBold_Regular.json', (font) => {
      const createTextMesh = (text: string, position: THREE.Vector3) => {
          const textGeometry = new TextGeometry(text, {
              font: font,
            size: 16, // Increased font size from 12 to 16
              depth: 1,
          });
          const textMaterial = new THREE.MeshBasicMaterial({color: 0x000000});
          const textMesh = new THREE.Mesh(textGeometry, textMaterial);
          textMesh.position.copy(position);
          return textMesh;
      };

      let textMesh = createTextMesh(`0 mm`, new THREE.Vector3(width, 30, 41));

      arrowGroup.add(textMesh);
      arrowGroup.userData.textMesh = textMesh;

      arrowGroup.userData.onDrag = (width: number, depth: number) => {
          arrowGroup.visible = true;
          const computedWidth = isSideWall ? depth : width;
          const newWidth = computedWidth / 2;
          const newArrowLength = newWidth - 25;

        // Update arrows by adjusting their length
        leftArrow.scale.set(newArrowLength/arrowLength, 1, 1);
        rightArrow.scale.set(newArrowLength/arrowLength, 1, 1);
        
        // Adjust arrow positions based on new width
        if (isSideWall) {
          arrowGroup.position.x = newWidth - 65;
        } else if (isTopWall) {
          arrowGroup.position.y = newWidth - 133;
        } else {
          arrowGroup.position.x = -(newWidth - 133);
        }

          // Update text position & content
          arrowGroup.remove(textMesh);
          textMesh.geometry.dispose();

          let textMeshPositionX = -newWidth + 211;
          if (isSideWall) textMeshPositionX = newWidth - 23;
          if (!isSideWall && isLshapedRoom) textMeshPositionX = newWidth - 15;

          textMesh = createTextMesh(
              `${(computedWidth / 0.2).toFixed(2)} mm`,
              new THREE.Vector3(textMeshPositionX, 30, isSideWall ? 41 : 38)
          );

          if (isSideWall) textMesh.rotateX(-Math.PI / 2);
          else {
              textMesh.rotateX(-Math.PI / 2);
              if (!isLshapedRoom) {
                  textMesh.rotateZ(Math.PI);
              }
          }

          arrowGroup.add(textMesh);
          arrowGroup.userData.textMesh = textMesh;
      };

      arrowGroup.userData.onDragEnd = () => {
          arrowGroup.visible = false;
      };
  });

  handleArrowsAndGroup([rightArrow, leftArrow], arrowGroup);

leftArrow.visible = true;
rightArrow.visible = true;
};

export const createRoomDimensionArrow = ({
    arrowDir,
    reverseArrowDir,
    arrowOrigin,
    arrowLength,
    onArrowsCreated,
    isVertical = false,
}: {
    arrowDir: THREE.Vector3;
    reverseArrowDir: THREE.Vector3;
    arrowOrigin: THREE.Vector3;
    arrowLength: number;
    isVertical?: boolean;
    onArrowsCreated?: (
        arrow: THREE.ArrowHelper,
        reverseArrow: THREE.ArrowHelper
    ) => void;
}): THREE.Group<THREE.Object3DEventMap> => {
    const arrowHeadLength = 20;
    const arrowHeadWidth = 16;
    let reverserArrowOrigin = new THREE.Vector3(
        arrowOrigin.x + arrowLength,
        arrowOrigin.y,
        arrowOrigin.z
    );

    if (isVertical) {
        reverserArrowOrigin = new THREE.Vector3(
            arrowOrigin.x,
            arrowOrigin.y + arrowLength,
            arrowOrigin.z
        );
    }

    const arrow = new THREE.ArrowHelper(
        arrowDir,
        arrowOrigin,
        arrowLength,
        0x000000,
        arrowHeadLength,
        arrowHeadWidth
    );

    // Create reverse arrow for double-header effect (top)
    const reverseArrow = new THREE.ArrowHelper(
        reverseArrowDir,
        reverserArrowOrigin,
        arrowLength,
        0x000000,
        arrowHeadLength,
        arrowHeadWidth
    );

    onArrowsCreated && onArrowsCreated(arrow, reverseArrow);

    const arrowGroup = new THREE.Group();
    arrowGroup.add(arrow, reverseArrow);

    return arrowGroup;
};



// Measurement Room Object still on progress on re factor

export const addDashedLineWall = (
  parentMesh: THREE.Mesh,
  handleLines: (lines: THREE.Line[]) => void,
  floorWidth: number,
  floorLength: number
) => {
  const dashedMaterial = new THREE.LineDashedMaterial({
      color: '#333',
      gapSize: 3,
  });

  // Geometry for the lines
  const leftLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(floorWidth / 2 - 50, 300, 0),
      new THREE.Vector3(-floorWidth / 2 - 50, 300, 0),
  ]);
  const rightLineGeometry = new THREE.BufferGeometry().setFromPoints([
      new THREE.Vector3(floorLength / 2 + 50, 300, 0),
      new THREE.Vector3(-floorLength / 2, 300, 0),
  ]);

  const leftLine = new THREE.Line(leftLineGeometry, dashedMaterial);
  const rightLine = new THREE.Line(rightLineGeometry, dashedMaterial);

  // Compute line distances for dashes
  leftLine.computeLineDistances();
  rightLine.computeLineDistances();

  leftLine.position.z = 27;
  rightLine.position.z = 27;
  rightLine.rotation.z = (90 * Math.PI) / 180;

  parentMesh.add(leftLine);
  parentMesh.add(rightLine);

  const leftLine2 = leftLine.clone();
  const rightLine2 = rightLine.clone();

  leftLine2.position.y = 200;
  rightLine2.position.x = 0;

  rightLine.position.y = floorLength / 2 - 0;
  rightLine2.position.y = floorLength / 2 - 0;

  
  leftLine.position.x = floorWidth / 2 + 25;
  leftLine2.position.x = floorWidth / 2 + 25;

  parentMesh.add(leftLine2);
  parentMesh.add(rightLine2);

  leftLine.userData.id = 'leftLine';
  leftLine2.userData.id = 'leftLine2';

  rightLine.userData.id = 'rightLine-wall';
  rightLine2.userData.id = 'rightLine2-wall';

  handleLines([rightLine, rightLine2]);

  leftLine.visible = false;
  leftLine2.visible = false;
  rightLine.visible = false;
  rightLine2.visible = false;

  return {
      leftLine,
      leftLine2,
      rightLine,
      rightLine2,
  };
};

export const addCabinetDimensionArrows = (
  cabinetGroup: THREE.Group,
  handleArrowsAndGroup: (
    arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[],
    arrowGroup?: THREE.Group<THREE.Object3DEventMap>,
  ) => void,
) => {

  // Get cabinet dimensions
  const box = new THREE.Box3().setFromObject(cabinetGroup)
  const size = new THREE.Vector3()
  box.getSize(size)
  const width = size.x
  const height = size.y
  const depth = size.z

  // console.log("Cabinet dimensions:", cabinetGroup)

  // Create a new group for the dimension indicators
  const dimensionGroup = new THREE.Group()
  dimensionGroup.name = "cabinetDimensionArrows"
  
  // Add a unique ID to identify this arrow group
  dimensionGroup.userData.id = "cabinetDimensionArrows"
  
  // Store references to the width, depth, and height groups for updates
  dimensionGroup.userData.widthGroup = null
  dimensionGroup.userData.depthGroup = null
  dimensionGroup.userData.heightGroup = null

  // Store all arrow objects and text materials to pass to the handler
  const arrowObjects: (THREE.ArrowHelper | THREE.SpriteMaterial)[] = []

  // Distance from cabinet surface
  const offset = 20
  const arrowColor = '#333'  // Corporate blue

  // Create width dimension arrows and update function
  const createWidthDimension = (width: number, depth: number, height: number) => {
    // Remove existing width group if any
    if (dimensionGroup.userData.widthGroup) {
      dimensionGroup.remove(dimensionGroup.userData.widthGroup)
    }
    
    // Width direction
    const widthDir = new THREE.Vector3(1, 0, 0).normalize()
    
    // Calculate positions
    const leftPos = new THREE.Vector3(0, depth, height + offset)
    const rightPos = new THREE.Vector3(width, depth, height + offset)
    const centerPos = new THREE.Vector3(width/2, depth-5, height + offset)
    
    // Calculate arrow lengths (leave space in center for label)
    const arrowSpacing = width * 0.15  // 15% of width as spacing
    const arrowLength = (width / 2) - arrowSpacing
    
    // Create left arrow (pointing from right to left)
    const leftArrow = new THREE.ArrowHelper(
      widthDir.clone().negate(),
      new THREE.Vector3(centerPos.x - arrowSpacing, centerPos.y, centerPos.z),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create right arrow (pointing from left to right)
    const rightArrow = new THREE.ArrowHelper(
      widthDir,
      new THREE.Vector3(centerPos.x + arrowSpacing, centerPos.y, centerPos.z),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create width label
    const canvas = document.createElement('canvas')
    canvas.width = 128
    canvas.height = 48
    
    const context = canvas.getContext('2d')
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    
    context.font = "bold 29px Arial";
    context.fillStyle = "black";
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    const displayDepth = cabinetGroup.userData.values.cabinet_width
    context.fillText(`${Math.round(displayDepth)} mm`, canvas.width / 2, canvas.height / 2)
    
    const texture = new THREE.CanvasTexture(canvas)
    const textMaterial = new THREE.SpriteMaterial({map: texture})
    const textSprite = new THREE.Sprite(textMaterial)
    textSprite.position.copy(centerPos)
    textSprite.scale.set(40, 15, 1)
    
    // Add everything to width group
    const widthGroup = new THREE.Group()
    widthGroup.name = "width-dimension"
    widthGroup.add(leftArrow)
    widthGroup.add(rightArrow)
    widthGroup.add(textSprite)
    dimensionGroup.add(widthGroup)
    
    // Store reference to width group for updates
    dimensionGroup.userData.widthGroup = widthGroup
    
    // Add to objects array for handler
    arrowObjects.push(leftArrow, rightArrow, textMaterial)
    
    return widthGroup
  }
  
  // Create depth dimension arrows and update function
  const createDepthDimension = (width: number, depth: number, height: number) => {
    // Remove existing depth group if any
    if (dimensionGroup.userData.depthGroup) {
      dimensionGroup.remove(dimensionGroup.userData.depthGroup)
    }
    
    // Depth direction (back to front)
    const depthDir = new THREE.Vector3(0, -1, 0).normalize()
    
    // Calculate positions
    const backPos = new THREE.Vector3(0, depth, height + offset)
    const frontPos = new THREE.Vector3(0, 0, height + offset)
    const centerPos = new THREE.Vector3(2, depth/2, height + offset)
    
    // Calculate arrow lengths (leave space in center for label)
    const arrowSpacing = depth * 0.05  // 5% of depth for consistent spacing
    const arrowLength = (depth /2) - arrowSpacing
    
    // Create back arrow (pointing from front to back)
    const backArrow = new THREE.ArrowHelper(
      depthDir.clone().negate(),
      new THREE.Vector3(centerPos.x, centerPos.y - arrowSpacing, centerPos.z),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create front arrow (pointing from back to front)
    const frontArrow = new THREE.ArrowHelper(
      depthDir,
      new THREE.Vector3(centerPos.x, centerPos.y + arrowSpacing, centerPos.z),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create rotated depth label to match arrow direction
    // Create depth label - horizontal like in the image
    const canvas = document.createElement('canvas')
    canvas.width = 128
    canvas.height = 48
    
    const context = canvas.getContext('2d')
    
    // Fill background
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    
    
    // Draw text horizontally (no rotation)
    context.font = "bold 29px Arial";
    context.fillStyle = "black";
    context.textAlign = 'center'
    context.textBaseline = 'middle'

      // Draw rotated text without complex canvas rotation
   

    const displayDepth = cabinetGroup.userData.values.cabinet_depth
    context.fillText(`${Math.round(displayDepth)} mm`, canvas.width/2, canvas.height/2)
    
    const texture = new THREE.CanvasTexture(canvas)
    const textMaterial = new THREE.SpriteMaterial({map: texture})
    const textSprite = new THREE.Sprite(textMaterial)
    
    // Position sprite at the center between arrows
    textSprite.position.copy(centerPos)

    // Rotate by a small amount (adjust as needed)
    textSprite.material.rotation = Math.PI / 2 * 0.9; 

    
    // Scale for horizontal label (same as width dimension)
    textSprite.scale.set(40, 15, 1)
      
    textSprite.position.copy(centerPos)
    
    // Adjust the position to place label appropriately relative to arrows
    textSprite.position.x += 12  // Increased offset for better visibility with rotated text
    // Restore the context to its original state
    context.restore()
  
    
    // Adjust scale for rotated label
    
    // Add everything to depth group
    const depthGroup = new THREE.Group()
    depthGroup.name = "depth-dimension"
    depthGroup.add(backArrow)
    depthGroup.add(frontArrow)
    depthGroup.add(textSprite)
    dimensionGroup.add(depthGroup)
    
    // Store reference to depth group for updates
    dimensionGroup.userData.depthGroup = depthGroup
    
    // Add to objects array for handler
    arrowObjects.push(backArrow, frontArrow, textMaterial)
    
    return depthGroup
  }
  
  // Create height dimension arrows and update function
  const createHeightDimension = (width: number, depth: number, height: number) => {
    // Remove existing height group if any
    if (dimensionGroup.userData.heightGroup) {
      dimensionGroup.remove(dimensionGroup.userData.heightGroup)
    }
    
    // Height direction (bottom to top)
    const heightDir = new THREE.Vector3(0, 0, 1).normalize()
    
    // Calculate positions
    const bottomPos = new THREE.Vector3(-offset, -20, 0)
    const topPos = new THREE.Vector3(-offset, -20, height)
    const centerPos = new THREE.Vector3(0, -20, height/2)
    
    // Calculate arrow lengths (leave space in center for label)
    const arrowSpacing = height * 0.15  // 15% of height as spacing
    const arrowLength = (height / 2) - arrowSpacing
    
    // Create bottom arrow (pointing down)
    const bottomArrow = new THREE.ArrowHelper(
      heightDir.clone().negate(),
      new THREE.Vector3(centerPos.x, centerPos.y, centerPos.z - arrowSpacing),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create top arrow (pointing up)
    const topArrow = new THREE.ArrowHelper(
      heightDir,
      new THREE.Vector3(centerPos.x, centerPos.y, centerPos.z + arrowSpacing),
      arrowLength,
      arrowColor,
      8,  // Head length
      5   // Head width
    )
    
    // Create height label with better approach for vertical text
    const canvas = document.createElement('canvas')
    canvas.width = 64
    canvas.height = 128
    
    const context = canvas.getContext('2d')
    
    // Fill background
    context.fillStyle = 'white'
    context.fillRect(0, 0, canvas.width, canvas.height)
    
    // Draw rotated text without complex canvas rotation
    context.save()
    context.translate(canvas.width/2, canvas.height/2)
    context.rotate(-Math.PI/2)  // Rotate 90 degrees counter-clockwise
    
    // Draw the text in the rotated context
    context.font = "bold 29px Arial";
    context.fillStyle = "black";
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    const displayHeight = cabinetGroup.userData.values.cabinet_height

    context.fillText(`${Math.round(displayHeight)} mm`, 0, 0)
    
    // Restore the context
    context.restore()
    
    const texture = new THREE.CanvasTexture(canvas)
    const textMaterial = new THREE.SpriteMaterial({map: texture})
    const textSprite = new THREE.Sprite(textMaterial)
    textSprite.position.copy(centerPos)
    textSprite.position.x = 12  // Offset slightly to the left for visibility
    textSprite.scale.set(20, 40, 1)  // Adjusted scale for better appearance
    
    // Add everything to height group
    const heightGroup = new THREE.Group()
    heightGroup.name = "height-dimension"
    heightGroup.add(bottomArrow)
    heightGroup.add(topArrow)
    heightGroup.add(textSprite)
    dimensionGroup.add(heightGroup)
    
    // Store reference to height group for updates
    dimensionGroup.userData.heightGroup = heightGroup
    
    // Add to objects array for handler
    arrowObjects.push(bottomArrow, topArrow, textMaterial)
    
    return heightGroup
  }
  
  // Create a function to update all dimensions
  dimensionGroup.userData.updateDimensions = (newWidth: number, newDepth: number, newHeight: number) => {
    // First, clear the arrowObjects array to avoid duplication
    arrowObjects.length = 0;
    
    // Recreate all dimension arrows with new dimensions
    createWidthDimension(newWidth, newDepth, newHeight);
    createDepthDimension(newWidth, newDepth, newHeight);
    createHeightDimension(newWidth, newDepth, newHeight);
    
    // Re-register with arrow handler
    handleArrowsAndGroup(arrowObjects, dimensionGroup);
  };
  
  // Create initial dimensions
  createWidthDimension(width, depth, height);
  createDepthDimension(width, depth, height);
  createHeightDimension(width, depth, height);

  // Add dimension group to the scene at the cabinet's position
  if (cabinetGroup.parent) {
    cabinetGroup.parent.add(dimensionGroup);
  } else {
    console.error("Cabinet has no parent, cannot add dimension arrows");
    return null;
  }

  // Position dimension group to match cabinet position
  dimensionGroup.position.copy(cabinetGroup.position);
  dimensionGroup.rotation.copy(cabinetGroup.rotation);

  // Set initial visibility to false
  dimensionGroup.visible = false;

  // Add onDrag and onDragEnd handlers
  dimensionGroup.userData.onDrag = (width: number, depth: number, height: number) => {
 
    dimensionGroup.visible = true;
    
    // Update arrow dimensions if width and depth are provided
    if (width && depth && height) {
      dimensionGroup.userData.updateDimensions(width, depth, height);
    }
  };

  dimensionGroup.userData.onDragEnd = () => {
    dimensionGroup.visible = false;
  };

  // Register with arrow handler
  handleArrowsAndGroup(arrowObjects, dimensionGroup);

  return dimensionGroup;
};

export const addHeightArrowsAndText = (
  wall: THREE.Mesh,
  handleArrows: (
      arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[]
  ) => void,
  wallHeight = 0,
  scale = 1,
  id = ''
) => {
  const arrowsAndText = [];
  const arrowColor = '#333';
  
  // Height Arrow Helper
  const heightArrowDir = new THREE.Vector3(0, 1, 0).normalize();
  const heightArrowLength = wallHeight - 50;

  // Create bottom arrow (height)
  const bottomArrow = new THREE.ArrowHelper(
      heightArrowDir.clone().negate(),
      new THREE.Vector3(-25, wallHeight - 25, 12.5),
      heightArrowLength,
      arrowColor,
      15,
      15
  );
  wall.add(bottomArrow);

  // Create top arrow (height)
  const topArrow = new THREE.ArrowHelper(
      heightArrowDir,
      new THREE.Vector3(-25, 25, 12.5),
      heightArrowLength,
      arrowColor,
      15,
      15
  );
  wall.add(topArrow);

  // Text for height
  const heightCanvas = document.createElement('canvas');
  const heightContext = heightCanvas.getContext('2d');
  heightCanvas.width = 256;
  heightCanvas.height = 128;

  heightContext!.font = '50px Arial';
  heightContext!.fillStyle = '#000';
  heightContext!.textAlign = 'center';
  
  // Calculate actual height in mm based on scale
  const heightInMm = Math.round((wallHeight / scale));
  heightContext!.fillText(`${heightInMm} mm`, heightCanvas.width / 2, heightCanvas.height / 2);

  const heightTexture = new THREE.CanvasTexture(heightCanvas);
  const heightTextMaterial = new THREE.SpriteMaterial({map: heightTexture});
  const heightTextSprite = new THREE.Sprite(heightTextMaterial);
  
  // Position the height text on the left side of the wall
  heightTextSprite.position.set(-75, wallHeight / 2, 20);
  heightTextSprite.scale.set(100, 50, 1);

  wall.add(heightTextSprite);

  // Set user data for all elements
  topArrow.userData.id = id;
  bottomArrow.userData.id = id;
  heightTextMaterial.userData.id = id;

  // Add all elements to the arrows array for handling
  arrowsAndText.push(topArrow, bottomArrow, heightTextMaterial);
  
  // Handle visibility through the callback
  handleArrows(arrowsAndText);

  // Hide by default
  topArrow.visible = false;
  bottomArrow.visible = false;
  heightTextMaterial.visible = false;
  
  return arrowsAndText;
};

export const addAllCabinetDimensionsToBackWall = (
  cabinetGroupList: THREE.Group<THREE.Object3DEventMap>[],
  backWall: THREE.Mesh,
  handleArrowsAndGroup: (
      arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[],
      arrowGroup?: THREE.Group<THREE.Object3DEventMap>
  ) => void,
  getGroupWidth: (group: THREE.Group<THREE.Object3DEventMap>) => {
      width: number;
      height: number;
      depth: number;
  }
): THREE.Group<THREE.Object3DEventMap> | null => {
  if (!cabinetGroupList || !cabinetGroupList.length || !backWall) return null;
  
  // Create a new group for the dimension arrows
  const dimensionGroup = new THREE.Group();
  dimensionGroup.name = "cabinetBackWallDimensions";
  dimensionGroup.userData.id = "cabinetBackWallDimensions";
  
  // Position the group near the top of the back wall
  const backWallPosition = backWall.position.clone();
  const backWallBoundingBox = new THREE.Box3().setFromObject(backWall);
  const backWallHeight = backWallBoundingBox.max.y - backWallBoundingBox.min.y;
  
  dimensionGroup.position.copy(backWallPosition);
  dimensionGroup.position.y += backWallHeight / 2 + 20; // Position above the back wall
  
  // Add text displaying cabinet dimensions
  const createDimensionText = (text: string, position: THREE.Vector3): THREE.Sprite => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      canvas.width = 512; // Wider canvas for more text
      canvas.height = 64;
      
      context.fillStyle = '#ffffff';
      context.fillRect(0, 0, canvas.width, canvas.height);
      
      context.font = 'Bold 18px Arial';
      context.fillStyle = '#000000';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(text, canvas.width / 2, canvas.height / 2);
      
      const texture = new THREE.CanvasTexture(canvas);
      const material = new THREE.SpriteMaterial({ map: texture });
      const sprite = new THREE.Sprite(material);
      sprite.scale.set(80, 10, 1);
      sprite.position.copy(position);
      
      return sprite;
  };
  
  // Function to update dimensions for all cabinets
  dimensionGroup.userData.updateAllDimensions = () => {
      // Clear existing children
      while (dimensionGroup.children.length > 0) {
          dimensionGroup.remove(dimensionGroup.children[0]);
      }
      
      if (!cabinetGroupList || cabinetGroupList.length === 0) return;
      
      // Create combined text for all cabinets
      let allCabinetsText = "Cabinet Dimensions:";
      
      cabinetGroupList.forEach((cabinet, index) => {
          if (!cabinet) return;
          
          const cabinetSize = getGroupWidth(cabinet);
          const formattedWidth = cabinetSize.width.toFixed(1);
          const formattedDepth = cabinetSize.depth.toFixed(1);
          const formattedHeight = cabinetSize.height.toFixed(1);
          
          // Add cabinet name and dimensions
          const cabinetName = cabinet.name || `Cabinet ${index + 1}`;
          allCabinetsText += ` ${cabinetName}: ${formattedWidth}x${formattedDepth}x${formattedHeight}`;
          
          // Add separator between cabinets
          if (index < cabinetGroupList.length - 1) {
              allCabinetsText += " | ";
          }
      });
      
      // Create the text sprite and add to dimensionGroup
      const textSprite = createDimensionText(allCabinetsText, new THREE.Vector3(0, 0, 0));
      dimensionGroup.add(textSprite);
  };
  
  // Initial update with current dimensions
  dimensionGroup.userData.updateAllDimensions();
  
  // Add the dimension group to the scene
  backWall.parent?.add(dimensionGroup);
  
  if (handleArrowsAndGroup) {
      handleArrowsAndGroup([], dimensionGroup);
  }
  
  return dimensionGroup;
};

export const addMultipleMeasurementsToBackWall = (
  backWall: THREE.Mesh,
  handleArrowsAndGroup: (arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[], arrowGroup?: THREE.Group) => void,
  wallHeight: number,
  cabinetGroupList?: THREE.Group<THREE.Object3DEventMap>[],
  getGroupWidth?: (group: THREE.Group<THREE.Object3DEventMap>) => {
    width: number
    height: number
    depth: number
  },
  roomWidth?: number,
  roomLength?: number,
  scale?: number,
) => {
  const arrowsGroup = new THREE.Group()

  arrowsGroup.name = "multipleMeasurementsGroup"
  arrowsGroup.userData.type = "multipleMeasurements"
  arrowsGroup.userData.updateVisibility = (show: boolean) => {
    arrowsGroup.visible = show
  }

  // Keep track of all arrow objects for updates
  const arrowObjects: (THREE.ArrowHelper | THREE.SpriteMaterial)[] = []

  // Map to store measurement components by cabinetId
  const measurementMap = new Map<
    string,
    {
      leftArrow: THREE.ArrowHelper
      rightArrow: THREE.ArrowHelper
      sprite: THREE.Sprite
    }
  >()

  // Define arrow properties outside the loop
  const arrowColor = "#333"
  const arrowHeadLength = 15
  const arrowHeadWidth = 15
  const arrowLength = 20

  // Generate measurements based on cabinet dimensions if cabinetGroupList is provided
  let finalMeasurements: {
    start: number;
    end: number;
    width: number;
    height: number;
    depth: number;
    position: number;
    cabinetId: string;
    label: string;
    dimensions: {
      width: number;
      height: number;
      depth: number;
    };
  }[] = [];

  if (cabinetGroupList && cabinetGroupList.length > 0 && getGroupWidth) {
    finalMeasurements = generateMeasurementsFromCabinets(cabinetGroupList, getGroupWidth);
  }

  // Function to check for and handle arrow collisions
  const handleMeasurementCollisions = (
    measurementsToCheck: { start: number; end: number; label: string; cabinetId?: string }[],
    positionY: number,
    spacing: number = 50 // Vertical spacing between colliding measurements
  ) => {
    // Sort measurements by position for easier collision detection
    const sortedMeasurements = [...measurementsToCheck].sort((a, b) => a.start - b.start);
    
    // Map to store adjusted Y positions for each measurement
    const adjustedPositions = new Map<string, number>();
    
    // Initialize with base position
    sortedMeasurements.forEach(measurement => {
      const id = measurement.cabinetId || `measurement-${sortedMeasurements.indexOf(measurement)}`;
      adjustedPositions.set(id, positionY);
    });
    
    // Check for collisions and adjust positions
    for (let i = 0; i < sortedMeasurements.length; i++) {
      const current = sortedMeasurements[i];
      const currentId = current.cabinetId || `measurement-${sortedMeasurements.indexOf(current)}`;
      
      // Check against all other measurements that come after this one
      for (let j = i + 1; j < sortedMeasurements.length; j++) {
        const other = sortedMeasurements[j];
        const otherId = other.cabinetId || `measurement-${sortedMeasurements.indexOf(other)}`;
        
        // Calculate distance between cabinet centers
        const currentCenter = (current.start + current.end) / 2;
        const otherCenter = (other.start + other.end) / 2;
        const distance = Math.abs(currentCenter - otherCenter);
        
        // Approximate width of a label (adjust based on your actual implementation)
        const labelWidth = 100;
        
        // Check if measurements are too close
        if (distance < labelWidth) {
          
          // Offset the second measurement vertically
          const currentPos = adjustedPositions.get(currentId) || positionY;
          let otherPos = adjustedPositions.get(otherId) || positionY;
          
          // Only adjust if they're currently at the same level
          if (Math.abs(currentPos - otherPos) < spacing) {
            otherPos = currentPos + spacing + 50;
            adjustedPositions.set(otherId, otherPos);
          }
        }
      }
    }
    
    return adjustedPositions;
  };

  // Create measurement arrows and labels function
  const createMeasurements = (
    measurementsToCreate: {
      start: number;
      end: number;
      width: number;
      height: number;
      depth: number;
      position: number;
      cabinetId: string;
      label: string;
      dimensions: {
        width: number;
        height: number;
        depth: number;
      };
    }[],
  ) => {
    // Clear existing children if there are any
    while (arrowsGroup.children.length > 0) {
      arrowsGroup.remove(arrowsGroup.children[0])
    }

    // Clear the arrowObjects array and measurement map
    arrowObjects.length = 0
    measurementMap.clear()

    // Define horizontal direction vectors (pointing inward for measurement)
    const leftToRightDir = new THREE.Vector3(1, 0, 0).normalize() // Points from left to right
    const rightToLeftDir = new THREE.Vector3(-1, 0, 0).normalize() // Points from right to left

    // Calculate adjusted positions based on collisions
    const basePositionY = wallHeight + 50; // Base position
    const adjustedPositions = handleMeasurementCollisions(measurementsToCreate, basePositionY);

    measurementsToCreate.forEach((measurement, index) => {
      const { start, end, label, cabinetId,position,dimensions,width } = measurement
    
      // Use cabinetId if available, otherwise fall back to index-based identification
      const measurementId = cabinetId || `measurement-${index}`

    
      // Find the corresponding cabinet group
      let cabinetGroup: THREE.Group<THREE.Object3DEventMap> | undefined
    
      if (cabinetId && cabinetGroupList) {
        cabinetGroup = cabinetGroupList.find((cabinet) => cabinet?.userData?.id === cabinetId)
      } else if (cabinetGroupList) {
        cabinetGroup = cabinetGroupList[index]
      }

    
      // Get accurate width for arrow length
      let actualWidth = width
      
   
      // Get the collision-adjusted Y position for this measurement
      const positionY = adjustedPositions.get(measurementId) || basePositionY;
    
      // Calculate positions based on cabinet position if available
      let xPosition = 0
      if (roomWidth && scale && cabinetGroup) {
        xPosition = -cabinetGroup?.position.x +
        (roomWidth * scale) / 2 -(width/2 - 35) 
      }
    
      // Create label first so we can position arrows relative to it
      const canvas = document.createElement("canvas")
      const context = canvas.getContext("2d")
      if (!context) return
    
      canvas.width = 156
      canvas.height = 64
    
      context.fillStyle = "black"
      context.font = "bold 32px Arial"
      context.textAlign = "center"
      context.textBaseline = "middle"
      
      // Display the actual width in the label
      const displayLabel = `${label} mm`
      context.fillText(displayLabel, canvas.width / 2, canvas.height / 2)
    
      const texture = new THREE.CanvasTexture(canvas)
      const spriteMaterial = new THREE.SpriteMaterial({ map: texture })
      const sprite = new THREE.Sprite(spriteMaterial)
      sprite.scale.set(100, 50, 1) // Size of the label
    
      // Position the label using the collision-adjusted Y position
      sprite.position.x = xPosition
      sprite.position.y = positionY + 50
      sprite.position.z = 25 // Ensure it's in front of arrows
    
      // Calculate the label's physical width in world units
      const labelWidth = sprite.scale.x // Approximate width of label in world units
      
      // Base the arrow length on the cabinet width
      // Convert mm to a reasonable Three.js unit with a scaling factor
      // We'll make the arrows extend from the label to half the cabinet width on each side
      const arrowScaleFactor = 0.4 // Adjust this factor to control how much of the cabinet width the arrows cover
      const cabinetWidthInUnits = actualWidth ;
      
      // Make sure the arrow has a minimum length regardless of cabinet width
      const minArrowLength = 40;
      const useArrowLength = Math.max(cabinetWidthInUnits, minArrowLength) ;
      
      // Position arrows relative to the label
      const arrowOffset = labelWidth ; // 10 units from the edge of label
    
      // Create left arrow (pointing right/inward)
      const leftArrow = new THREE.ArrowHelper(
        new THREE.Vector3(1, 0, 0).normalize(), // Direction vector (pointing right)
        new THREE.Vector3(xPosition , positionY, 20), // Start position
        width/2, // Length of arrow - now based on cabinet width
        arrowColor,
        arrowHeadLength,
        arrowHeadWidth
      )
    
      // Store the measurement ID in the arrow's userData
      leftArrow.userData.measurementId = measurementId
      leftArrow.userData.arrowType = "left"
    
      // Create right arrow (pointing left/inward)
      const rightArrow = new THREE.ArrowHelper(
        new THREE.Vector3(-1, 0, 0).normalize(), // Direction vector (pointing left)
        new THREE.Vector3(xPosition  , positionY, 20), // End position
        width/2, // Length of arrow - now based on cabinet width
        arrowColor,
        arrowHeadLength,
        arrowHeadWidth
      )
    
      // Store the measurement ID in the arrow's userData
      rightArrow.userData.measurementId = measurementId
      rightArrow.userData.arrowType = "right"
    
      // Add everything to the group
      arrowsGroup.add(sprite)
      arrowsGroup.add(leftArrow)
      arrowsGroup.add(rightArrow)
      
      // Add to arrowObjects array
      arrowObjects.push(spriteMaterial)
      arrowObjects.push(leftArrow)
      arrowObjects.push(rightArrow)
    
      // Store the measurement components in the map
      measurementMap.set(measurementId, {
        leftArrow,
        rightArrow,
        sprite,
      })
    })

    // Call the handler function with arrow objects
    handleArrowsAndGroup(arrowObjects, arrowsGroup)
  }

  // Create the initial measurements
  createMeasurements(finalMeasurements)

  // Add update method to the group's userData
  arrowsGroup.userData.updateMeasurements = (cabinetGroupList?: THREE.Group<THREE.Object3DEventMap>[]) => {
    let updatedMeasurements = finalMeasurements;

    if (cabinetGroupList && cabinetGroupList.length > 0 && getGroupWidth) {
      updatedMeasurements = generateMeasurementsFromCabinets(cabinetGroupList, getGroupWidth);
    }

    // This will clear all existing arrows and create new ones
    createMeasurements(updatedMeasurements);
  };

  // Add method to update positions when cabinets move
  arrowsGroup.userData.updatePositionsForCabinet = (cabinetId: string) => {
    if (!cabinetGroupList || !roomWidth || !scale) return

    // Find the cabinet with the given ID
    const cabinetGroup = cabinetGroupList.find((cabinet) => cabinet.userData.id === cabinetId)
    if (!cabinetGroup) return

    // Get the measurement components associated with this cabinet
    const measurement = measurementMap.get(cabinetId)
    if (!measurement) return

    const { leftArrow, rightArrow, sprite } = measurement

    // Update positions
    const newX = -cabinetGroup.position.x + (roomWidth * scale) / 2 - 158

    leftArrow.position.x = newX
    rightArrow.position.x = newX
    sprite.position.x = newX
    
    // After updating positions, we should check for collisions and adjust if needed
    if (cabinetGroupList) {
      // Get current measurements
      const currentMeasurements = cabinetGroupList.map((cabinet, index) => {
        const cabinetId = cabinet.userData.id || `cabinet-${index}`;
        // You'll need to extract start/end from cabinet position/dimensions
        // This is a simplified approximation
        let start = 0;
        let end = 0;
        
        if (getGroupWidth) {
          try {
            const dimensions = getGroupWidth(cabinet);
            const width = dimensions.width;
            const centerX = cabinet.position.x;
            start = centerX - width/2;
            end = centerX + width/2;
          } catch (error) {
            console.warn(`Error getting cabinet dimensions: ${error}`);
          }
        }
        
        return {
          start,
          end,
          label: `${end-start} mm`,
          cabinetId
        };
      });
      
      // Calculate new Y positions
      const basePositionY = wallHeight + 50;
      const adjustedPositions = handleMeasurementCollisions(currentMeasurements, basePositionY);
      // Apply new Y positions to all measurements
      for (const [id, components] of Array.from(measurementMap.entries())) {
        const newY = adjustedPositions.get(id);
        if (newY !== undefined) {
          components.leftArrow.position.y = newY;
          components.rightArrow.position.y = newY;
          components.sprite.position.y = newY;
        }
      }
    }
  }

  // Add method to update positions for all cabinets
  arrowsGroup.userData.updateAllPositions = () => {
    if (!cabinetGroupList || !roomWidth || !scale) return

    // Update X positions first
    cabinetGroupList.forEach((cabinet) => {
      const cabinetId = cabinet.userData.id
      if (cabinetId) {
        // Get the measurement components associated with this cabinet
        const measurement = measurementMap.get(cabinetId)
        if (measurement) {
          const { leftArrow, rightArrow, sprite } = measurement
          // Update X positions
          const newX = -cabinet.position.x + (roomWidth * scale) / 2 - 158
          leftArrow.position.x = newX
          rightArrow.position.x = newX
          sprite.position.x = newX
        }
      }
    })
    
    // After updating all X positions, check for collisions and adjust Y positions
    if (cabinetGroupList) {
      // Get current measurements
      const currentMeasurements = cabinetGroupList.map((cabinet, index) => {
        const cabinetId = cabinet.userData.id || `cabinet-${index}`;
        // Extract start/end from cabinet position/dimensions
        let start = 0;
        let end = 0;
        
        if (getGroupWidth) {
          try {
            const dimensions = getGroupWidth(cabinet);
            const width = dimensions.width;
            const centerX = cabinet.position.x;
            start = centerX - width/2;
            end = centerX + width/2;
          } catch (error) {
            console.warn(`Error getting cabinet dimensions: ${error}`);
          }
        }
        
        return {
          start,
          end,
          label: `${end-start} mm`,
          cabinetId
        };
      });
      
      // Calculate new Y positions
      const basePositionY = wallHeight + 50;
      const adjustedPositions = handleMeasurementCollisions(currentMeasurements, basePositionY);
      
      // Apply new Y positions to all measurements
      for (const [id, components] of Array.from(measurementMap.entries())) {
        const newY = adjustedPositions.get(id);
        if (newY !== undefined) {
          components.leftArrow.position.y = newY;
          components.rightArrow.position.y = newY;
          components.sprite.position.y = newY;
        }
      }
    }
  }

  backWall.add(arrowsGroup)
  return arrowsGroup
}

export const addEmptySpaceMeasurements = (
  backWall: THREE.Mesh,
  cabinetGroupList: THREE.Group<THREE.Object3DEventMap>[],
  getGroupWidth: (group: THREE.Group<THREE.Object3DEventMap>) => {
    width: number
    height: number
    depth: number
  },
  wallHeight: number,
  roomWidth?: number,
  scale?: number,
  handleArrowsAndGroup?: (arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[], arrowGroup?: THREE.Group) => void,
) => {
  // Create a group for the empty space arrows
  const emptySpaceGroup = new THREE.Group()
  emptySpaceGroup.name = "emptySpaceMeasurementsGroup"
  emptySpaceGroup.userData.type = "emptySpaceMeasurements"
  emptySpaceGroup.userData.updateVisibility = (show: boolean) => {
    emptySpaceGroup.visible = show
  }

  // Keep track of arrow objects
  const arrowObjects: (THREE.ArrowHelper | THREE.SpriteMaterial)[] = []

  // If no cabinets and no room width, no empty spaces to measure
  if ((!cabinetGroupList || cabinetGroupList.length === 0) && !roomWidth) {
    backWall.add(emptySpaceGroup)
    return emptySpaceGroup
  }

  // Special case: If there are no cabinets but we know the room width
  if ((!cabinetGroupList || cabinetGroupList.length === 0) && roomWidth) {
    // Measure the entire wall as empty space
    const gaps = [{
      start: 0,
      end: roomWidth,
      width: roomWidth,
      leftCabinetId: "wallStart",
      rightCabinetId: "wallEnd"
    }];
    
    createEmptySpaceMeasurements(gaps, wallHeight, roomWidth, scale, arrowObjects, emptySpaceGroup);
    
    if (handleArrowsAndGroup) {
      handleArrowsAndGroup(arrowObjects, emptySpaceGroup);
    }
    
    backWall.add(emptySpaceGroup);
    return emptySpaceGroup;
  }

  // Extract cabinet positions and dimensions
  const cabinetPositions = cabinetGroupList.map(cabinet => {
    try {
      const dimensions = getGroupWidth(cabinet)
      const width = Math.round(dimensions.width)
      const centerX = cabinet.position.x
      
      return {
        id: cabinet.userData.id || undefined,
        left: centerX - width / 2,
        right: centerX + width / 2,
        width: width
      }
    } catch (error) {
      console.warn(`Error getting cabinet dimensions: ${error}`)
      return null
    }
  }).filter(Boolean) // Remove any nulls

  // Sort cabinets by left position
  cabinetPositions.sort((a, b) => a.left - b.left)

  const gaps = [];
  
  // Special case: Only one cabinet
  if (cabinetPositions.length === 1 && roomWidth) {
    const cabinet = cabinetPositions[0];
    
    // Always measure empty space from left wall to cabinet (if there is a gap)
    if (cabinet.left > 0) {
      gaps.push({
        start: 0,
        end: cabinet.left,
        width: cabinet.left,
        leftCabinetId: "wallStart",
        rightCabinetId: cabinet.id
      });
    }
    
    // Always measure empty space from cabinet to right wall (if there is a gap)
    if (cabinet.right < roomWidth) {
      gaps.push({
        start: cabinet.right,
        end: roomWidth,
        width: roomWidth - cabinet.right,
        leftCabinetId: cabinet.id,
        rightCabinetId: "wallEnd"
      });
    }
  } else {
    // Normal case: Multiple cabinets - find gaps between them
    for (let i = 0; i < cabinetPositions.length - 1; i++) {
      const currentCabinet = cabinetPositions[i]
      const nextCabinet = cabinetPositions[i + 1]
      
      // Calculate the gap between current cabinet's right edge and next cabinet's left edge
      const gapWidth = nextCabinet.left - currentCabinet.right
      
      // Only include significant gaps (e.g., greater than 50mm)
      if (gapWidth > 0) {
        gaps.push({
          start: currentCabinet.right,
          end: nextCabinet.left,
          width: gapWidth,
          leftCabinetId: currentCabinet.id,
          rightCabinetId: nextCabinet.id
        })
      }
    }

    // Also check for gap between wall start and first cabinet
    if (cabinetPositions.length > 0 && cabinetPositions[0].left > 0) {
      gaps.unshift({
        start: 0,
        end: cabinetPositions[0].left,
        width: cabinetPositions[0].left,
        leftCabinetId: "wallStart",
        rightCabinetId: cabinetPositions[0].id
      })
    }

    // Check for gap between last cabinet and wall end
    if (cabinetPositions.length > 0 && roomWidth && cabinetPositions[cabinetPositions.length - 1].right < roomWidth) {
      gaps.push({
        start: cabinetPositions[cabinetPositions.length - 1].right,
        end: roomWidth,
        width: roomWidth - cabinetPositions[cabinetPositions.length - 1].right,
        leftCabinetId: cabinetPositions[cabinetPositions.length - 1].id,
        rightCabinetId: "wallEnd"
      })
    }
  }

  // Create measurements for each gap
  createEmptySpaceMeasurements(gaps, wallHeight, roomWidth, scale, arrowObjects, emptySpaceGroup);

  // Add the handler function if provided
  if (handleArrowsAndGroup) {
    handleArrowsAndGroup(arrowObjects, emptySpaceGroup)
  }

  // Add update method
  emptySpaceGroup.userData.updateEmptySpaces = () => {
    // Remove all existing measurements
    while (emptySpaceGroup.children.length > 0) {
      emptySpaceGroup.remove(emptySpaceGroup.children[0])
    }
    
    // Clear the arrowObjects array
    arrowObjects.length = 0
    
    // Recreate empty space measurements with updated cabinet positions
    const updatedGroup = addEmptySpaceMeasurements(
      backWall, 
      cabinetGroupList, 
      getGroupWidth, 
      wallHeight, 
      roomWidth, 
      scale, 
      handleArrowsAndGroup
    );
    
    // Find cabinet measurements group to check for collisions
    const cabinetMeasurements = backWall.children.find(
      child => child.name === 'multipleMeasurementsGroup'
    ) as THREE.Group;
    
    // Handle collisions between cabinet and empty space labels
    if (cabinetMeasurements) {
      handleCabinetEmptySpaceCollisions(cabinetMeasurements, updatedGroup);
    }
    
    return updatedGroup;
  }

  // Add to back wall
  backWall.add(emptySpaceGroup)
  
  // Find cabinet measurements group to check for collisions
  const cabinetMeasurements = backWall.children.find(
    child => child.name === 'multipleMeasurementsGroup'
  ) as THREE.Group;
  
  // Handle collisions between cabinet and empty space labels
  if (cabinetMeasurements) {
    handleCabinetEmptySpaceCollisions(cabinetMeasurements, emptySpaceGroup);
  }
  
  return emptySpaceGroup
}
export const updateAllMeasurements = (
  wallRefs: React.MutableRefObject<THREE.Mesh[]>,
  cabinetGroupList: THREE.Group<THREE.Object3DEventMap>[]
) => {
  // Find the back wall
  const backWall = wallRefs.current.find(
    (wall) => wall.userData.id === 'backWall'
  );
  
  if (!backWall) {
    return;
  }
  
  // Find the cabinet measurements group
  const cabinetMeasurements = backWall.children.find(
    (child) => child.name === 'multipleMeasurementsGroup'
  ) as THREE.Group;
  
  // Find the empty space measurements group
  const emptySpaceMeasurements = backWall.children.find(
    (child) => child.name === 'emptySpaceMeasurementsGroup'
  ) as THREE.Group;
  
  // Update cabinet measurements if they exist
  if (cabinetMeasurements && 
      cabinetMeasurements.userData && 
      typeof cabinetMeasurements.userData.updateAllPositions === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    cabinetMeasurements.userData.updateAllPositions();
  }
  
  // Update empty space measurements if they exist
  if (emptySpaceMeasurements && 
      emptySpaceMeasurements.userData && 
      typeof emptySpaceMeasurements.userData.updateEmptySpaces === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    emptySpaceMeasurements.userData.updateEmptySpaces();
  }
  
  // After updating both measurement groups, handle collisions between them
  if (cabinetMeasurements && emptySpaceMeasurements) {
    handleCabinetEmptySpaceCollisions(cabinetMeasurements, emptySpaceMeasurements);
  }
};

const generateMeasurementsFromCabinets = (
  cabinetGroupList: THREE.Group<THREE.Object3DEventMap>[],
  getGroupWidth: (group: THREE.Group<THREE.Object3DEventMap>) => {
    width: number
    height: number
    depth: number
  }
) => {
  return cabinetGroupList.map((cabinet, index) => {
    try {
      const {width, height, depth} = getGroupWidth(cabinet);
      const position = cabinet.position.x;
      const cabinetId = cabinet.userData.id || `cabinet-${index}`;
      const label = cabinet.userData.values.cabinet_width;
      
      return {
        start: position - width/2,
        end: position + width/2,
        width,
        height,
        depth,
        position,
        cabinetId,
        label: `${label}`,
        dimensions: {
          width,
          height,
          depth
        }
      };
    } catch (error) {
      console.warn(`Error generating measurement for cabinet: ${error}`);
      return {
        start: 0,
        end: 100,
        width: 100,
        height: 0,
        depth: 0,
        position: 0,
        cabinetId: cabinet.userData.id || `cabinet-${index}`,
        label: "100 mm",
        dimensions: {
          width: 100,
          height: 0,
          depth: 0
        }
      };
    }
  });
};
export const setupMeasurements = (
  wallRefs: React.MutableRefObject<THREE.Mesh[]>,
  cabinetGroupList: THREE.Group<THREE.Object3DEventMap>[] | undefined,
  handleArrowsAndGroup: (arrows: (THREE.ArrowHelper | THREE.SpriteMaterial)[], arrowGroup?: THREE.Group) => void,
  getGroupWidth: (group: THREE.Group<THREE.Object3DEventMap>) => {
    width: number
    height: number
    depth: number
  },
  roomType: string,
  rectangularDimension: {
    length: number,
    width: number,
    height: number
  },
  lShapedDimension: {
    leftWidth: number,
    rightWidth: number,
    height: number
  },
  scale: number,
  showArrows: boolean
) => {
  if (!cabinetGroupList || cabinetGroupList.length === 0) {
    return null;
  }

  // Find the back wall
  const backWall = wallRefs.current.find(
    (wall) => wall.userData.id === 'backWall'
  );
  if (!backWall) {
    return null;
  }

  // Get the scene
  const scene = backWall.parent;
  if (!scene) {
    return null;
  }

  // Calculate room dimensions
  const roomLength =
    roomType === 'RECTANGULAR'
      ? rectangularDimension.length
      : lShapedDimension.leftWidth;
  const roomWidth =
    roomType === 'RECTANGULAR'
      ? rectangularDimension.width
      : lShapedDimension.rightWidth;
  const roomHeight =
    roomType === 'RECTANGULAR'
      ? rectangularDimension.height * scale + 20
      : lShapedDimension.height * scale + 20;
      
  // Clean up any existing measurement groups
  const removeExistingMeasurements = (
    parent: THREE.Object3D<THREE.Object3DEventMap>
  ) => {
    // Get all direct children that are measurement groups
    const measurementChildren = [];

    for (let i = parent.children.length - 1; i >= 0; i--) {
      const child = parent.children[i];

      // Check if this is a measurement group by name or userData
      if (
        child.name === 'multipleMeasurementsGroup' ||
        child.name === 'emptySpaceMeasurementsGroup' ||
        child.name === 'cabinetBackWallDimensions' ||
        (child.userData &&
          (child.userData.type === 'multipleMeasurements' || 
           child.userData.type === 'emptySpaceMeasurements'))
      ) {
        measurementChildren.push(child);
        parent.remove(child);
      }

      // Also recursively check this child's children
      if (child.children && child.children.length > 0) {
        removeExistingMeasurements(child);
      }
    }

    return measurementChildren.length;
  };

  // Clean the entire scene to be thorough
  const sceneRemoveCount = removeExistingMeasurements(scene);

  // Also specifically clean the back wall
  const wallRemoveCount = removeExistingMeasurements(backWall);

  // Now create a new cabinet measurements group
  const measurementsGroup = addMultipleMeasurementsToBackWall(
    backWall,
    handleArrowsAndGroup,
    roomHeight,
    cabinetGroupList,
    getGroupWidth,
    roomWidth,
    roomLength,
    scale
  );

  // Set visibility for cabinet measurements
  if (measurementsGroup && typeof measurementsGroup.userData.updateVisibility === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    measurementsGroup.userData.updateVisibility(showArrows);
  }

  // Now create empty space measurements group
  const emptySpaceGroup = addEmptySpaceMeasurements(
    backWall,
    cabinetGroupList,
    getGroupWidth,
    roomHeight,
    roomWidth,
    scale,
    handleArrowsAndGroup
  );

  // Set visibility for empty space measurements
  if (emptySpaceGroup && typeof emptySpaceGroup.userData.updateVisibility === 'function') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    emptySpaceGroup.userData.updateVisibility(showArrows);
  }

  // Handle collisions between cabinet and empty space measurements
  // (This is already handled inside addEmptySpaceMeasurements now, but adding it here for completeness)
  handleCabinetEmptySpaceCollisions(measurementsGroup, emptySpaceGroup);

  // Return both measurement groups for reference
  return {
    cabinetMeasurements: measurementsGroup,
    emptySpaceMeasurements: emptySpaceGroup
  };
};

function createEmptySpaceMeasurements(
  gaps: {
    start: number;
    end: number;
    width: number;
    leftCabinetId: string | undefined;
    rightCabinetId: string | undefined;
  }[],
  wallHeight: number,
  roomWidth?: number,
  scale?: number,
  arrowObjects: (THREE.ArrowHelper | THREE.SpriteMaterial)[] = [],
  emptySpaceGroup: THREE.Group = new THREE.Group()
) {
  const arrowColor = "#333";
  const basePositionY = wallHeight + 100;
  const minimumGapForArrows = 100; // Minimum gap width to show arrows
  const minimumGapToDisplay = 100; // Don't display gaps smaller than 50mm
  const maximumGapToDisplay = 8000; // Don't display gaps larger than 8000mm
  const arrowHeadLength = 15;
  const arrowHeadWidth = 15;
  
  // Filter out gaps that are too small or too large
  const filteredGaps = gaps.filter(gap => {
    const displayWidth = Math.round(gap.width * 5);
    return displayWidth >= minimumGapToDisplay && displayWidth <= maximumGapToDisplay;
  });
  
  // If all gaps were filtered out, return early
  if (filteredGaps.length === 0) {
    return emptySpaceGroup;
  }
  
  // Function to handle collision detection and adjustment
  const handleMeasurementCollisions = (
    gapsToCheck: {
      start: number;
      end: number;
      width: number;
      leftCabinetId: string | undefined;
      rightCabinetId: string | undefined;
    }[],
    positionY: number,
    spacing: number = 50 // Vertical spacing between colliding measurements
  ) => {
    // Sort gaps by position for easier collision detection
    const sortedGaps = [...gapsToCheck].sort((a, b) => a.start - b.start);
    
    // Map to store adjusted Y positions for each gap
    const adjustedPositions = new Map<number, number>();
    
    // Initialize with base position
    sortedGaps.forEach((gap, index) => {
      adjustedPositions.set(index, positionY);
    });
    
    // Check for collisions and adjust positions
    for (let i = 0; i < sortedGaps.length; i++) {
      const current = sortedGaps[i];
      
      // Check against all other gaps that come after this one
      for (let j = i + 1; j < sortedGaps.length; j++) {
        const other = sortedGaps[j];
        
        // Check if the current gap's end overlaps with the next gap's start
        if (current.end > other.start - 180) { // 180 accounts for label width
          // This is a collision - hide the current measurement
          adjustedPositions.set(i, -9999); // Move it far away (effectively hide it)
          break;
        }
        
        // Calculate distance between gap centers
        const currentCenter = (current.start + current.end) / 2;
        const otherCenter = (other.start + other.end) / 2;
        const distance = Math.abs(currentCenter - otherCenter);
        
        // Approximate width of a label plus some padding
        const labelWidth = 156;
        
        // Check if measurements are too close for labels (but arrows don't overlap)
        if (distance < labelWidth) {
          // Offset the second measurement vertically
          const currentPos = adjustedPositions.get(i) || positionY;
          let otherPos = adjustedPositions.get(j) || positionY;
          
          // Only adjust if they're currently at the same level
          if (Math.abs(currentPos - otherPos) < spacing) {
            otherPos = currentPos + spacing;
            adjustedPositions.set(j, otherPos);
          }
        }
      }
    }
    
    return adjustedPositions;
  };
  
  // Calculate adjusted positions based on collisions
  const adjustedPositions = handleMeasurementCollisions(filteredGaps, basePositionY);

  filteredGaps.forEach((gap, index) => {
    // Get the collision-adjusted Y position for this measurement
    const positionY = adjustedPositions.get(index) || basePositionY;
    
    // Skip if this measurement should be hidden (collision detected)
    if (positionY === -9999) {
      return;
    }
    
    // Calculate positions based on gap edges
    const gapWidth = gap.end - gap.start;
    
    // Calculate center position
    let centerX = (gap.start + gap.end) / 2;
    
    // Adjust position if roomWidth and scale are available
    if (roomWidth && scale) {
      centerX = -centerX + (roomWidth * scale) / 2;
    }
    
    // Create label with matching style to createMeasurements
    const canvas = document.createElement("canvas");
    canvas.width = 156;
    canvas.height = 64;
    
    const context = canvas.getContext("2d");
    if (!context) return;
    
    // Fill background
    context.fillStyle = "white";
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = arrowColor;
    context.lineWidth = 2;
    context.strokeRect(0, 0, canvas.width, canvas.height);
    
    // Draw text
    context.fillStyle = "black";
    context.font = "bold 34px Arial";
    context.textAlign = "center";
    context.textBaseline = "middle";
    
    // Display the gap width in mm
    const displayWidth = Math.round(gap.width * 5);
    const displayLabel = `${displayWidth} mm`;
    context.fillText(displayLabel, canvas.width / 2, canvas.height / 2);
    
    const texture = new THREE.CanvasTexture(canvas);
    const textMaterial = new THREE.SpriteMaterial({map: texture});
    const textSprite = new THREE.Sprite(textMaterial);
    
    // Position sprite at center with the adjusted Y position
    textSprite.position.set(centerX, positionY + 50, 25);
    
    // Scale consistently with other dimension labels
    textSprite.scale.set(100, 50, 1);
    
    // Add label to group
    // emptySpaceGroup.add(textSprite);
    // arrowObjects.push(textMaterial);
    
    // Calculate actual positions for left and right arrows
    let leftArrowX = gap.start;
    let rightArrowX = gap.end;
    
    if (roomWidth && scale) {
      leftArrowX = -leftArrowX + (roomWidth * scale) / 2 ;
      rightArrowX = -rightArrowX + (roomWidth * scale) / 2 ;
    }
    
    // Width direction vectors
    const leftToRightDir = new THREE.Vector3(1, 0, 0).normalize();
    const rightToLeftDir = new THREE.Vector3(-1, 0, 0).normalize();
    
    // Create left arrow (pointing right/inward)
    const leftArrow = new THREE.ArrowHelper(
      leftToRightDir,
      new THREE.Vector3(leftArrowX, positionY, 20),
      gapWidth / 2 , // Match style from image
      arrowColor,
      arrowHeadLength,
      arrowHeadWidth
    );
    
    // Create right arrow (pointing left/inward)
    const rightArrow = new THREE.ArrowHelper(
      rightToLeftDir,
      new THREE.Vector3(rightArrowX, positionY, 20),
      gapWidth / 2 , // Match style from image
      arrowColor,
      arrowHeadLength,
      arrowHeadWidth
    );
    
    // Store gap info in arrow userData
    leftArrow.userData.gapId = index;
    rightArrow.userData.gapId = index;
    
    // Add arrows to group
    // emptySpaceGroup.add(leftArrow);
    // emptySpaceGroup.add(rightArrow);
    
    // // Add to arrow objects array
    // arrowObjects.push(leftArrow);
    // arrowObjects.push(rightArrow);
  });
  
  return emptySpaceGroup;
}

function handleCabinetEmptySpaceCollisions (
  cabinetMeasurements: THREE.Group,
  emptySpaceMeasurements: THREE.Group
) {
  // Skip if either group doesn't exist
  if (!cabinetMeasurements || !emptySpaceMeasurements) return;
  
  // Get all cabinet label sprites
  const cabinetLabels: THREE.Sprite[] = [];
  cabinetMeasurements.traverse(object => {
    if (object instanceof THREE.Sprite) {
      cabinetLabels.push(object);
    }
  });
  
  // Get all empty space label sprites
  const emptySpaceLabels: THREE.Sprite[] = [];
  emptySpaceMeasurements.traverse(object => {
    if (object instanceof THREE.Sprite) {
      emptySpaceLabels.push(object);
    }
  });
  
  // Check for collisions between cabinet and empty space labels
  emptySpaceLabels.forEach(emptyLabel => {
    // Get all related arrows in the same group as this label
    const relatedArrows: THREE.ArrowHelper[] = [];
    if (emptyLabel.parent) {
      emptyLabel.parent.traverse(child => {
        if (child instanceof THREE.ArrowHelper && 
            Math.abs(child.position.x - emptyLabel.position.x) < 100) {
          relatedArrows.push(child);
        }
      });
    }
    
    // Check against each cabinet label
    cabinetLabels.forEach(cabinetLabel => {
      // Calculate horizontal distance
      const distance = Math.abs(emptyLabel.position.x - cabinetLabel.position.x);
      
      // Approximate label width (adjust based on your actual label size)
      const labelWidth = 120;
      
      // Check if the labels are too close horizontally and at the same height
      if (distance < labelWidth && Math.abs(emptyLabel.position.y - cabinetLabel.position.y) < 10) {
        // Move the empty space label up by 50 units
        emptyLabel.position.y += 50;
        
        // Also move any associated arrows
        relatedArrows.forEach(arrow => {
          arrow.position.y += 50;
        });
      }
    });
  });
};
// End of measurement 


