import {ReactNode} from 'react';

export enum Event {
    ROOM_PLANNER_3D_PREVIEW,
    ROOM_PLANNER_EDGE_PREVIEW,
    ROOM_PLANNER_DOOR_PREVIEW,
    ROOM_PLANNER_DRWAWER_PREVIEW,
    ROOM_PLANNER_SHELVES_PREVIEW,
    ROOM_PLANNER_PREVIEW_TYPE,
    RESET_SELECTED_MENU,
}

type Callback = (data?: ReactNode) => void;

/**
 * A simple PubSub (Publish-Subscribe) class for event handling.
 */
class PubSub {
    private events = new Map<Event, Callback[]>([
        [Event.ROOM_PLANNER_3D_PREVIEW, []],
        [Event.ROOM_PLANNER_EDGE_PREVIEW, []],
        [Event.ROOM_PLANNER_DOOR_PREVIEW, []],
        [Event.ROOM_PLANNER_DRWAWER_PREVIEW, []],
        [Event.ROOM_PLANNER_SHELVES_PREVIEW, []],
        [Event.ROOM_PLANNER_PREVIEW_TYPE, []],
        [Event.RESET_SELECTED_MENU, []],
    ]);
    private log = false;

    /**
     * Creates an instance of PubSub.
     * @param {Object} options - The options for the PubSub instance.
     * @param {boolean} [options.log=false] - Whether to log actions to the console.
     */
    constructor({log = false}: {log?: boolean}) {
        if (typeof log === 'boolean') {
            this.log = log;
        }
    }

    /**
     * Subscribes a callback function to an event.
     * @param {Event} event - The event to subscribe to.
     * @param {Callback} callback - The callback function to be called when the event is published.
     */
    subscribe(event: Event, callback: Callback) {
        if (this.events.has(event)) {
            // eslint-disable-next-line no-console
            this.log && console.log(`Subscribed to ${event}`);
            this.events.get(event).push(callback);
        }
    }

    /**
     * Publishes an event, calling all subscribed callback functions with the provided data.
     * @param {Event} event - The event to publish.
     * @param {ReactNode} data - The data to pass to the callback functions.
     */
    publish(event: Event, data?: ReactNode) {
        if (this.events.has(event)) {
            // eslint-disable-next-line no-console
            this.log && console.log(`Published to ${event}`);
            this.events.get(event).forEach((callback) => {
                callback(data);
            });
        }
    }

    /**
     * Unsubscribes a callback function from an event.
     * @param {Event} event - The event to unsubscribe from.
     * @param {Callback} callback - The callback function to be removed.
     */
    unsubscribe(event: Event, callback: Callback) {
        if (this.events.has(event)) {
            // eslint-disable-next-line no-console
            this.log && console.log(`Unsubscribed from ${event}`);
            const subscribers =
                this.events
                    .get(event)
                    .filter((subscriber) => subscriber !== callback) || [];

            this.events.set(event, subscribers);
        }
    }
}

export const pubSub = new PubSub({});
