import React, {useEffect, useMemo} from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {LayoutDesigner} from 'config/entity/menu/LayoutDesigner';
import {Nav} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {Link} from 'shared/reload/Link';
import styled from 'styled-components';
import {ProductSelector} from 'components/customer/RoomPlanner/components/Menu/ProductSelector';
import {
    MenuItem,
    useMenuContext,
} from 'components/customer/RoomPlanner/components/Menu/MenuContext';
import {Preview} from 'components/customer/RoomPlanner/components/Menu/Preview';
import {Hardware} from 'components/customer/RoomPlanner/components/Menu/Hardware';
import {Materials} from 'components/customer/RoomPlanner/components/Menu/Materials';
import {useSearchParams} from 'react-router-dom';
import {useAppSelector} from 'store/customer';
import {getisRoomPlannerEditing} from '../../store/roomPlannerSlice';

export interface MenuProps {
    menu: LayoutDesigner[];
    isFooter?: boolean;
    isCollapsed: boolean;
}

export const MenuComponent = ({
    menu,
    isFooter = false,
    isCollapsed,
}: MenuProps) => {
    const isSmallDevice = isDeviceSmall();
    const {selectedMenu, hideMenu, handleClick} = useMenuContext();

    if (isSmallDevice) return null;
    if (menu === undefined) return null;

    const [searchParams] = useSearchParams();

    const isRoomPlannerEditing = useAppSelector(getisRoomPlannerEditing);

    // we need to detect route changes so we can hide or show the drawer
    const productIsSelected = useMemo(() => {
        return !!searchParams.get('product');
    }, [searchParams]);

    useEffect(() => {
        if (!isRoomPlannerEditing) {
            hideMenu();
        }
    }, [isRoomPlannerEditing]);

    // we need to separate this to the render code so that we can apply certain conditions
    const renderRoomPlannerMenu = useMemo(() => {
        if (
            selectedMenu === '' &&
            !isRoomPlannerEditing &&
            !productIsSelected
        ) {
            return null;
        }

        const menuComponents: Partial<Record<MenuItem, JSX.Element>> = {
            [MenuItem.ADD_PRODUCT]: (
                <>
                    <div>
                        <ProductSelector />
                    </div>
                    <Preview />
                </>
            ),
            [MenuItem.HARDWARE_SELECTIONS]: <Hardware />,
            [MenuItem.MATERIALS]: <Materials />,
        };

        return menuComponents[selectedMenu as MenuItem] || null;
    }, [selectedMenu, productIsSelected, isRoomPlannerEditing]);

    return (
        <>
            <NavLocal className="flex-column job-menu">
                {menu.map((item) => (
                    <Nav.Link
                        as={Link}
                        key={item.name}
                        to={undefined}
                        className={`${
                            selectedMenu == item.event ? 'active' : ''
                        }`}
                        onClick={handleClick(item.event)}>
                        <div>
                            <Icon iconName={item.icon} title={item.name} />
                        </div>
                        {!isCollapsed ? <label>{item.name}</label> : null}
                    </Nav.Link>
                ))}
            </NavLocal>
            {!isFooter ? (
                <RoomPlannerMenu $isCollapsed={isCollapsed}>
                    {renderRoomPlannerMenu}
                </RoomPlannerMenu>
            ) : null}
        </>
    );
};

const NavLocal = styled(Nav)`
    &:hover {
        background-color: transparent !important;
    }
`;

const RoomPlannerMenu = styled.div<{$isCollapsed: boolean}>`
    position: fixed;
    top: 85px;
    left: ${({$isCollapsed}) => ($isCollapsed ? '95px' : '250px')};
    display: flex;
    gap: 10px;
    background-color: transparent !important;
    align-items: flex-start;

    > div:first-child {
        width: 400px;

        .nav-and-control {
            width: 400px;
            right: initial;
            border-bottom-left-radius: 8px !important;
            border-bottom-right-radius: 8px !important;
        }
    }

    > div {
        background-color: white !important;
        box-shadow: 2px 2px 10px -1px #bdbdbd;
        border-radius: 8px;
        padding: 15px;
        max-width: 400px;
        max-height: calc(100vh - 100px);
        overflow-y: auto;
        overflow-x: hidden;
    }
`;
